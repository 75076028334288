import AddToCartButton from '../../components/button-add-to-cart';
import * as actionTypes from 'shop-packages/analytics/action-types';
import {pushEvent} from "shop-packages/analytics";

export default class CombineVariant {

	constructor() {
		this.init();
	}

	init() {
		this.fetchCombineVariant();
    this.disabledClickButton();
		this.sizeSelection();
		this.addToCart();
		this.goToMiniBasket();
	}

	fetchCombineVariant() {
    $('.js-combin-add-to-cart .js-add-to-cart-button').addClass('disabled');
		this.$container = $(document.getElementById('ProductPage'));
		const $variants = this.$container.find('.js-combine-products');
		$variants.each((index, el) => {
			const pk = el.getAttribute('data-pk');
			$.ajax({
				url: `/product/${pk}/`,
				beforeSend(xhr) {
					xhr.setRequestHeader('Accept', 'application/json');
				},
				success: (response) => {
					const sizeData = response.variants.filter(
						item => item.attribute_key == 'integration_beden1'
					)[0]?.options;
					sizeData && sizeData.forEach(item => {
						const option = document.createElement('option');
						option.value = item.product.pk;
						option.innerText = item.label;
						if (item.is_selectable === false || item.in_stock === false) {
							option.disabled = true;
						}
						$(el).find('.js-combin-sizes').append(option);
					});
				}
			});
		});
	}

  disabledClickButton() {
    $('.js-combin-add-to-cart').on('click', function() {
      if ($(this).find('button').hasClass('disabled')) {
        $(this).closest('.js-combine-products').find('.js-size-selection-error').addClass('d-block');
      }
    });
  }

	sizeSelection() {
		const sizeSelection = $('.js-combin-sizes');
		const $this = this;

		sizeSelection.on('change', function (e) {
			$(e.currentTarget).parents('.js-combine-products').find('.js-combine-success').hide();
			const targetElement = $(e.currentTarget).parent().siblings().find('.js-add-to-cart');
			targetElement.attr('data-product', $(e.currentTarget).val());
			targetElement.removeClass('disabled');
      const targetMessage = $(e.currentTarget).parents('.js-combine-products').find('.js-size-selection-error');
      targetMessage.removeClass('d-block')
			$this.addToCart();
		});
	}

	addToCart() {
		const $addToCartList = $('.js-add-to-cart');
		this.errorLabelElement = $('.js-size-selection-error');

		$addToCartList.each((i, el) => {
			const $addToCart = $(el);

			if (!$addToCart.length) {
				return;
			}

			const addToCartButton = AddToCartButton($addToCart);

			addToCartButton.success = (response) => {
				const { data: { basket } = {} } = response;

				$(el).parents('.js-combine-products').find('.js-combine-success').show();

				$('.analytics-data').each(function () {
					const analyticsData = JSON.parse($(this).text());
					if (!analyticsData.type) return;
					if (analyticsData.type === actionTypes.productViewed) {
						const productData = {
							type: actionTypes.productAdded,
							payload: {
								products: [{
									...analyticsData.payload.products[0],
									quantity: 1,
									cart_id: basket.pk
								}]
							}
						};
						pushEvent(productData)
					}
				});

				let cartProducts = [];
				basket.basketitem_set.forEach((item) => {
					let obj = {
						cart_id: basket.pk,
						itemgroup_id: item.product.attributes.integration_renk_kodu && item.product.attributes.integration_beden1 ? item.product.base_code + item.product.attributes.integration_renk_kodu + item.product.attributes.integration_beden1 : item.product.base_code,
						productPK: item.product.pk,
						sku: item.product.sku,
						quantity: item.quantity,
						price: item.product.price,
						variantid: item.product.attributes.filterable_renk
					};
					cartProducts.push(obj)
				});

				const cartDetailData = {
					type: actionTypes.cartDetailEvent,
					payload: cartProducts
				};

				pushEvent(cartDetailData);
			};

			addToCartButton.error = (response) => {
				const _this = this;
				if ($addToCart.attr('data-product').length < 1) {
					_this.errorLabelElement.addClass('red');
					_this.errorLabelElement.removeClass('d-none');
					_this.errorLabelElement.text('Beden seçimi yapmalısınız.');
				  } else if (response && response.responseJSON && response.responseJSON.error_code) {
					_this.errorLabelElement.addClass('red');
					_this.errorLabelElement.text(response.responseJSON.non_field_errors).removeClass('d-none').addClass('text-danger');
				  }
			};
		});
	}

	goToMiniBasket() {
		$('.js-combin-open-mini-basket').on('click', function() {
			if (this.mobile) {
				$('.js-mini-basket-mobile').fadeIn('500');
			} else {
				$('.js-mini-basket-full-screen-content').css('display', 'block');
			}
			const htmlBodySelector = $('html,body');
			htmlBodySelector.addClass('overflow-hidden');
		});

		const $mobilBasketPopupClose = $('.js-mini-basket-mobile-continue');
		$mobilBasketPopupClose.on('click', () => {
			const htmlBodySelector = $('html,body');
			htmlBodySelector.removeClass('overflow-hidden');
			$('.js-mini-basket-mobile').fadeOut('500');
		});
	}
}